
import { ref, defineComponent, onMounted, computed } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Actions, Modules } from "@/store/enums/StoreEnums";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "CreateEventChooseEnvironment",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    let isLoading = computed(() => {
      return store.getters[Modules.EVENT + "getIsLoading"];
    });

    let eventEnvironments = computed(() => {
      return store.getters[Modules.MASTER + "getEventEnvironments"];
    });

    const submitButton = ref<HTMLButtonElement | null>(null);

    const schema = yup.object({
      event_environment_id: yup.string().required().label("Environment"),
    });

    const { meta, errors, handleSubmit, isSubmitting, setErrors } = useForm({
      initialValues: {
        event_environment_id: "",
      },
      validationSchema: schema,
    });

    const { value: event_environment_id } = useField("event_environment_id");

    const onSubmit = handleSubmit((values) => {
      router.push({
        name: "createEventComplete",
        query: {
          environment: values.event_environment_id,
        },
      });
    });

    onMounted(() => {
      setCurrentPageTitle("Create Event");

      let query = Object.assign({}, route.query);
      if (query.environment) {
        event_environment_id.value = query.environment;
        delete query.environment;
        router.replace({ query });
      }
    });

    return {
      isLoading,
      eventEnvironments,

      submitButton,
      meta,
      errors,
      onSubmit,
      isSubmitting,

      event_environment_id,
    };
  },
});
